import React from "react";

const HomePageTemplates = () => {
  return (
    <div className="relative min-h-screen flex flex-col gap-10 items-center justify-center">
      <div className="flex items-center justify-center gap-3">
        <h1 className="text-6xl font-semibold text-custom-pink">
          Template Design
        </h1>
        <span className="min-h-[5px] mt-3 min-w-[60px] bg-custom-pink"></span>
      </div>
    </div>
  );
};

export default HomePageTemplates;
