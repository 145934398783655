export const templatesData = [
    {
        title: "Template A",
        image: "assets/images/",
    },
    {
        title: "Template B",
        image: "assets/images/",
    },
    {
        title: "Template C",
        image: "assets/images/",
    },
]