export const AccountBankData = [
  {
    name: "BCA",
  },
  {
    name: "CeritaBank",
  },
  {
    name: "Mandiri",
  },
];
